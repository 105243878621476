export interface OrderElement {
    name: string //der name ist gleich der OrderType aus dem unteren Enum
    isOrder: Boolean, //historisch schlecht gewachsen, aktuell ist es !!inBearbeitung!!
    responsible: string,
    responsibleStartDate: string,
    //Bei STATIC und PROBATIO_PLANS werden die folgenden Feldern nicht im FE angezeigt, weil sie keine relevanz haben.
    assigned: { //eine Beauftragung kann beauftragt sein oder nicht, mit Datum
        isAssigned: boolean,
        date: string
    },
    delivered: {
        isDelivered: boolean, //ausgeliefert auf OrderElement Ebene
        date: string
    }
}

export enum OrderPosition {
    STATIC, //ist den anderen Typen eigentlich uebergeordnet
    PROBATIO_PLANS, //ist den anderen Typen eigentlich uebergeordnet
    GEG_EVIDENCE,
    ENEV, // ist der GEG - Ausweiss
    SOUNDPROOFING ,
    FIREPROTECTION ,
    CONSTRUCTION_SUPERVISION
}

export enum OrderTypes {
    STATIC= 'STATIC', //ist den anderen Typen eigentlich uebergeordnet
    PROBATIO_PLANS = 'PROBATIO_PLANS', //ist den anderen Typen eigentlich uebergeordnet
    ENEV = 'ENEV', // ist der GEG - Ausweiss
    SOUNDPROOFING = 'SOUNDPROOFING',
    FIREPROTECTION = 'FIREPROTECTION',
    GEG_EVIDENCE = 'GEG_EVIDENCE',
    CONSTRUCTION_SUPERVISION = 'CONSTRUCTION_SUPERVISION'
}
