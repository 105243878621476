


































import {Component, Prop} from "vue-property-decorator";
import Base, {Form} from "@/data/model/Base";
import Tooltip from "@/components/Tooltip.vue";

@Component({
  components: {Tooltip}
})
export default class OpenTypeGroupOverview extends Base {
  constructor() {
    super('openTypeGroupOverview')
  }

  @Prop({required: true})
  orderElements: any

  @Prop({required: true})
  orderType: any
  @Prop({required: true})
  elementCount: any

  @Prop({required: false, default: 0})
  allElementCount!: number

  private addOpenOrderType() {
    this.$store.commit('openOrderTypeForTable', [this.orderType])
    this.$router.push('/admin/manageprojects')
  }
}
