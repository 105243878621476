








































































































import {Component} from "vue-property-decorator";
import Base from "@/data/model/Base";
import {Getter} from "vuex";
import {Project} from '@/data/model/Project';
import {OrderElement, OrderTypes} from "@/data/model/OrderElement";
import OpenTypeGroupOverview from "@/components/OpenTypeGroupOverview.vue";
import Tooltip from "@/components/Tooltip.vue";

@Component({
  components: {Tooltip, OpenTypeGroupOverview}
})

export default class ManageDashboard extends Base {

  constructor() {
    super('manage-dashboard')
  }

  private projects: Array<Project> = []

  private orderElementsStatic: Array<OrderElement> = []
  private allOrderElementsStatic = 0

  private orderElementsProbation: Array<OrderElement> = []
  private allOrderElementsProbation = 0

  private orderElementsFire: Array<OrderElement> = []
  private allOrderElementsFire = 0

  private orderElementsSound: Array<OrderElement> = []
  private allOrderElementsSound = 0

  private orderElementsEnev: Array<OrderElement> = []
  private allOrderElementsEnev = 0

  private orderElementsGegEvidence: Array<OrderElement> = []
  private allOrderElementsGegEvidence = 0

  private orderElementConstructionSupervision: Array<OrderElement> = []
  private allOrderElementConstructionSupervision = 0

  private dialog = true

  get links() {
    return [
      {icon: 'mdi-account-multiple', label: this.getLangText('link_label_user'), link: '/admin/manageuser'},
      {icon: 'mdi-home-city', label: this.getLangText('link_label_projects'), link: '/admin/manageprojects'},
    ]
  }

  get orderType() {
    return OrderTypes
  }

  created() {
    this.refreshProjects()
  }

  private refreshProjects() {
    console.log('refreshProjects')
    this.$store.dispatch('getAllProjects').then(projects => {
      this.projects = projects
      this.countOpenOrder(projects)
    })
  }

  private countOpenOrder(projects: Array<Project>) {
    projects.forEach(project => {
      project.order?.forEach((orderElement: OrderElement) => {
        let order: any = orderElement
        order['projectId'] = project.id
        let type = order.name
        if (type === OrderTypes.STATIC) {
          this.allOrderElementsStatic++
          if (!order.isOrder) {
            this.orderElementsStatic.push(order)
          }
        }
        if (type === OrderTypes.PROBATIO_PLANS) {
          this.allOrderElementsProbation++
          if (!order.isOrder) {
            this.orderElementsProbation.push(order)
          }
        }
        if (type === OrderTypes.FIREPROTECTION) {
          this.allOrderElementsFire++
          if (this.filterOpenOrders(order)) {
            this.orderElementsFire.push(order)
          }
        }
        if (type === OrderTypes.SOUNDPROOFING) {
          this.allOrderElementsSound++
          if (this.filterOpenOrders(order)) {
            this.orderElementsSound.push(order)
          }
        }
        if (type === OrderTypes.ENEV) {
          this.allOrderElementsEnev++
          if (this.filterOpenOrders(order)) {
            this.orderElementsEnev.push(order)
          }
        }
        if (type === OrderTypes.GEG_EVIDENCE) {
          this.allOrderElementsGegEvidence++
          if (this.filterOpenOrders(order)) {
            this.orderElementsGegEvidence.push(order)
          }
        }
        if (type === OrderTypes.CONSTRUCTION_SUPERVISION) {
          this.allOrderElementConstructionSupervision++
          if (this.filterOpenOrders(order)) {
            this.orderElementConstructionSupervision.push(order)
          }
        }
      })
    })
  }

  private filterOpenOrders(order: OrderElement) {
    if ((order.assigned && order.assigned.isAssigned) && !order.isOrder && (order.delivered && !order.delivered.isDelivered)) {
      return true
    }
    return false
  }

  private addOpenOrderType(openOrderTypes: Array<string>) {
    this.$store.commit('openOrderTypeForTable', openOrderTypes)
    this.$router.push('/admin/manageprojects')
  }
}
